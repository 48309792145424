<template>
  <v-dialog
    v-model="getDialog"
    @click:outside="handleClickOutSite"
    ref="dialog"
    max-width="100%"
    content-class="title-close basic-unit-dialog"
    scrollable
    persistent
    :retain-focus="false"
  >
    <v-card>
      <v-card-title>
        <span>{{ dialogTitle }}</span>
        <div class="button-close" @click="close">
          <img src="@/assets/images/dialog/close.svg" class="button-close_close" />
          <img src="@/assets/images/dialog/close_active.svg" class="button-close_active" />
        </div>
      </v-card-title>
      <v-card-text class="pt-4 pb-4">
        <slot></slot>
      </v-card-text>

      <v-card-actions v-if="hasAction">
        <common-button class="" @action="close" :label="cancelText" />
        <common-button
          :class="disabled ? 'btn-disabled' : 'right-button-popup'"
          :disabled="disabled"
          @action="submit"
          :label="confirmText"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import CommonButton from '@/components/commonApp/Button.vue';
export default {
  components: { CommonButton },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    dialogTitle: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: '',
    },
    hasAction: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    close() {
      if (this.isLoading) {
        return;
      }
      this.$emit('close');
    },
    handleClickOutSite() {
      this.close();
    },
    submit() {
      this.$emit('submit');
    },
  },
  computed: {
    getDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/styles/components/popup/index.scss';
::v-deep .v-card__text {
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  font-size: 16px !important;
  font-weight: normal !important;
  white-space: pre-line !important;
}
::v-deep .v-btn {
  text-transform: inherit !important;
}
</style>
